/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8e17f0a9-68eb-4d7b-a588-16571adf55f9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wIjpP34a9",
    "aws_user_pools_web_client_id": "6m5n344uea9c78cuni5upul0u9",
    "oauth": {}
};


export default awsmobile;
